import React from "react";
import ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import userReducer from "./store/movies";
import imgReducer from "./store/img";
import currentuserReducer from "./store/Currentuser";

import App from "./App";
//import histoty from "./histoty";

const store = configureStore({
    reducer:{
        user:userReducer,
        img:imgReducer,
        currentuser:currentuserReducer
    }
});

//const root = ReactDom.createRoot(document.getElementById('root'));

ReactDom.render(
    <BrowserRouter >
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>,document.getElementById('root')
);