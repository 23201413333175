import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postvalue } from "./store/movies";
import { imgvalue } from "./store/img";

import Searchbar from "./Searchbar";
import "./Results.css";

const Resultpage = () =>{
    //const movie = useSelector(state => state.user.value);
    const movie = window.localStorage.getItem('searchedmovie');
    const [smovies,setSmovies] = useState([]);
    const base_img_url="https://image.tmdb.org/t/p/w500";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(async() => {
        
            const res = await axios.get(`https://api.themoviedb.org/3/search/movie?api_key=bcc4ff10c2939665232d75d8bf0ec093&query=${movie}`);
            setSmovies(res.data.results);
            //console.log(res.data.results);
        
    },[movie]);

    useEffect(()=>{
        const us = window.localStorage.getItem('cu');
        const user = JSON.parse(us);
        //console.log(user,'from homepage to not login');
        if(user === null){
            navigate('/');
        }
    });

    const filteredmovies = smovies.filter((mov)=>{
        return mov.poster_path !== null
    });

    const setmovieinfo = (movie) => {
        const smi = {
            title:movie.title,
            img:movie.poster_path
        }
        window.localStorage.setItem('sm',JSON.stringify(smi));
    }

    return(
        <>  
            <Searchbar />
            <div className="topic">Results</div>
            {filteredmovies.length > 0 ?(
                <div className="body-container">
                <div className="movie-box">
                    {filteredmovies.map((movie)=>{
                        return(
                            <div key={movie.id} className="movie-container" >
                                <div className="poster-containter"><img src={base_img_url+movie.poster_path} alt="img" className="poster"/></div>
                                <Link to="/details" onClick={()=>{
                                    dispatch(postvalue(movie.title));
                                    dispatch(imgvalue(movie.poster_path));
                                    setmovieinfo(movie);
                                }} >
                                    <div className="title">{movie.title}</div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
            ):(<div className="nomovies">No Results Found</div>)}
            
        </>
    );
}

export default Resultpage;

/*
filteredmovies.length > 0 && movie !== '' && movie !== undefined 
{smovies && smovies.length === 0 &&(
                
            )}
            {movie === '' && !smovies && smovies.length !== 0  &&(
                <div className="nomovies">No Results Found</div>
            )}
            */