import React from "react";
import { Routes,Route } from "react-router-dom"

import Intro from "./components/Intropage";
import Homepage from "./Homepage";
import Resultpage from "./Resultpage";
import Detailpage from "./Detailpage";
import Nomovies from "./Nomovies";
import Signup from "./components/Signup";
import Login from "./components/Login";
import Star from "./components/Star";

const App = () =>{
    return(
        <Routes>
            <Route path="/" exact element={<Intro />} />
            <Route path="/home" exact element={<Homepage />} />
            <Route path="/results" exact element={<Resultpage />} />
            <Route path="/details" exact element={<Detailpage />} />
            <Route path="/no-results-found" exact element={<Nomovies />} />
            <Route path="/signup" exact element={<Signup />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/star" exact element={<Star />} />
        </Routes>
    );
}

export default App;