import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name:"user",
    initialState:{ value:'' },
    reducers:{
        postvalue:(state,action)=>{
            state.value=action.payload;
        }
    }
});

export const{ postvalue } = userSlice.actions;
export default userSlice.reducer;