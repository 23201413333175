import React,{ useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";

import { postvalue } from "./store/movies";
import "./Searchbar.css";

const Searchbar = () =>{
    const [ivalue,setIvalue] = useState();
    //const [cudata,setCudata] = useState({signedin:false});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
   /* useEffect(()=>{
        const data = window.localStorage.getItem('cu');
        setCudata(JSON.parse(data));  
    },[]);*/
    //console.log(cudata.signedin);
    const searchvalue = () =>{
        const temp = ivalue;
        if(temp !== undefined){
            //console.log(temp);
            dispatch(postvalue(temp));
            window.localStorage.setItem('searchedmovie',temp)
        }
    }

    const onvaluechange = (event) =>{
        setIvalue(event.target.value);
    }

    const logout = () => {
        window.localStorage.clear('cu');
        navigate('/');
    }

    return(
        <div className="header-container">
            <div className="sub-header-container">
                <Link to="/home" className="logo">flickinfo</Link>
                <div>
                    <button className="searchbutton" onClick={logout}>
                        logout
                    </button>
                </div>
            </div>
            <div className="searchbar-container">
                <div className="search-block">
                    <input value={ivalue} type='text' onChange={onvaluechange} placeholder="search movies,series..." className="searchbar" />
                    <Link to="/results">
                        <button onClick={searchvalue} className="searchbutton" >Search</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Searchbar;

/*
#CAE962  green

#FFFFFF4D   #FFFFFF1A    gray black

#FFFFFF  white  

#CCCCCC placeholder

#FFFFFF0D inputtext
*/