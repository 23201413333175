import React from "react";

import Searchbar from "./Searchbar";

const Nomovies = () => {
    return(
        <>
            <Searchbar />
            <div><h2>No Movies Found</h2></div>
        </>
    );
}

export default Nomovies; 