import React, { useEffect, useState } from 'react';
import StarRatingComponent from "react-star-rating-component";
import axios from 'axios';

import "../css/Star.css"

const Star = ({data}) => {
    const cmi = window.localStorage.getItem('cm');
    const umi = window.localStorage.getItem('cu');
    const [ cuser,setCuser ] = useState([]);
    const [ cmovie,setCmovie ] = useState([]);
    const [ rating,setRating ] = useState([]);
    const [ rate,setRate ] = useState(0);
    const [ scored,setScored ] = useState(false);
    const [hv,setHv] = useState("");
    const [usercount,setUsercount] = useState();
    var [ b,setB ] = useState(0);
    const [ b1,setB1 ] = useState('white');
    const [ b2,setB2 ] = useState('white');
    const [ b3,setB3 ] = useState('white');
    const [ b4,setB4 ] = useState('white');
    const [ b5,setB5 ] = useState('white');
    const [ mb1,setMb1 ] = useState('black');
    const [ mb2,setMb2 ] = useState('black');
    const [ mb3,setMb3 ] = useState('black');
    const [ mb4,setMb4 ] = useState('black');
    const [ mb5,setMb5 ] = useState('black');
    useEffect(()=>{
        setCmovie(JSON.parse(cmi));
        //console.log(cmovie,'botherhood');
    },[cmi]);

    //console.log(cuser,'botherhood');
    //console.log(rating)
    useEffect(()=>{
        //console.log(rate,'reference');
        if(b !== null ){
            if(b === 0){
                //console.log("from button 1");
                setB1('white');
                setB2('white');
                setB3('white');
                setB4('white');
                setB5('white');
            }
            if(b === 1){
                //console.log("from button 1");
                setB1('yelow');
                setB2('white');
                setB3('white');
                setB4('white');
                setB5('white');
            }
            if(b === 2){
                //console.log("from button 2");
                setB1('yellow');
                setB2('yellow');
                setB3('white');
                setB4('white');
                setB5('white');
            }
            if(b === 3){
                //console.log("from button 3");
                setB1('yellow');
                setB2('yellow');
                setB3('yellow');
                setB4('white');
                setB5('white');
            }
            if(b === 4){
                //console.log("from button 4");
                setB1('yellow');
                setB2('yellow');
                setB3('yellow');
                setB4('yellow');
                setB5('white');
            }
            if(b === 5){
                //console.log("from button 5");
                setB1('yellow');
                setB2('yellow');
                setB3('yellow');
                setB4('yellow');
                setB5('yellow');
            }
        }
        if(rate !== null ){
            if(rate === 0){
                //console.log("from button 1");
                setMb1('black');
                setMb2('black');
                setMb3('black');
                setMb4('black');
                setMb5('black');
            }
            if(rate >= 1 && rate < 2){
                //console.log("from button 1");
                setMb1('yelow');
                setMb2('black');
                setMb3('black');
                setMb4('black');
                setMb5('black');
            }
            if(rate >= 2 && rate < 3){
                //console.log("from button 2");
                setMb1('yellow');
                setMb2('yellow');
                setMb3('black');
                setMb4('black');
                setMb5('black');
            }
            if(rate >= 3 && rate < 4){
                //console.log("from button 3");
                setMb1('yellow');
                setMb2('yellow');
                setMb3('yellow');
                setMb4('black');
                setMb5('black');
            }
            if(rate >= 4 && rate < 5){
                //console.log("from button 4");
                setMb1('yellow');
                setMb2('yellow');
                setMb3('yellow');
                setMb4('yellow');
                setMb5('black');
            }
            if(rate >= 5 && rate < 6){
                //console.log("from button 5");
                setMb1('yellow');
                setMb2('yellow');
                setMb3('yellow');
                setMb4('yellow');
                setMb5('yellow');
            }
        }
        //setRate(7+5);
        //console.log(rate);
        setHv(data);
        let l = rating.length;
        const n = [];
        let sum = 0;
        rating.map(((rating)=>{
            n.push(rating.rating); 
        }));
        n.forEach((item)=>{
            sum += item;
            //console.log(sum);            
        });
        let f = sum/l;
        //console.log(f);
        if(f !== NaN && f !== null && f !== 0){
            setRate(f);
            //console.log(rate,'goal achieved')
        }
        const t = rating.filter((r)=>{ return r.name === cuser.name } );
        //console.log(t,'single');
        if( t.length === 1 ){
            setScored(true);
            //console.log(scored);
        }
        //console.log(scored);
    });

    useEffect(()=>{
        if(cmovie.imdbID !== undefined){
            axios.get(`https://json-server-public.onrender.com/rating?movieid=${cmovie.imdbID}`)
            .then((res)=>{
                //console.log(res.data);
                setRating(res.data);
                setCuser(JSON.parse(umi));
                setUsercount(res.data.length);
                
            })
            .catch((error)=>console.log(error));
        }
    },[hv]);

    const onrate = () => {
        const ri = {
            id:0,
            movieid:cmovie.imdbID,
            name:cuser.name, 
            rating:b
        }
        if( b !== 0 ){
            axios.post('https://json-server-public.onrender.com/rating',ri)
            .then(()=>{
                console.log("reviewed");
                setScored(true);
                
                    axios.get(`https://json-server-public.onrender.com/rating?movieid=${cmovie.imdbID}`)
                    .then((res)=>{
                        //console.log(res.data);
                        setRating(res.data);
                        setCuser(JSON.parse(umi));
                        setUsercount(res.data.length);
                        
                    })
                    .catch((error)=>console.log(error));
                })
            .catch((error)=>console.log(error));
        }
    }

    return (
        <div className='review-container'>
            <div className='star-container'>
                <div className='mstar-container'>
                    <div className='mstar'>
                        <button style={{ color:`${mb1}` }}>★</button>
                        <button style={{ color:`${mb2}` }}>★</button>
                        <button style={{ color:`${mb3}` }}>★</button>
                        <button style={{ color:`${mb4}` }}>★</button>
                        <button style={{ color:`${mb5}` }}>★</button>
                    </div>
                </div>
                <div className='user-count'>({usercount} user reviewed)</div>
            </div>
            <div>
                {scored ? (<div style={{ color:'white' }} ></div>) :
                    (<div>
                        <div className='ratemovie'>
                            rate this movie
                        </div>
                        <div className='rating-star'>
                            <div>
                                <button className='star' onClick={()=>setB(1)} style={{ color:`${b1}` }}>★</button>
                                <button className='star' onClick={()=>setB(2)} style={{ color:`${b2}` }}>★</button>
                                <button className='star' onClick={()=>setB(3)} style={{ color:`${b3}` }}>★</button>
                                <button className='star' onClick={()=>setB(4)} style={{ color:`${b4}` }}>★</button>
                                <button className='star' onClick={()=>setB(5)} style={{ color:`${b5}` }}>★</button>
                            </div>
                            <button className='rate-button' onClick={onrate}>
                                Rate
                            </button>
                        </div>
                    </div>) 
                }
                
            </div>
        </div>
    );
}
export default Star; 

/* 
<StarRatingComponent name="rate1" 
                        editing={false} 
                        starCount={5}
                        value={rate}
                    />
*/
