import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Star from "./components/Star";
import Searchbar from "./Searchbar";
import "./Details.css";

const Detailpage = () =>{
    //const movie = useSelector(state => state.user.value);
    const [smovie,setSmovie] = useState([]);
    const [com,setCom] = useState([]);
    const [comvalue,setComvalue] = useState();
    const [hv,setHv] = useState("");    
    const base_img_url="https://image.tmdb.org/t/p/w500";
    //const img = useSelector(state => state.img.value);
    const us = window.localStorage.getItem('cu');
    const user = JSON.parse(us);
    const mi = window.localStorage.getItem('sm');
    const movie = JSON.parse(mi);
  //  const filteredcomments = [];
    const navigate = useNavigate();

    useEffect(()=>{
        if(smovie.imdbID !== undefined){
            axios.get(`https://json-server-public.onrender.com/items?movieid=${smovie.imdbID}`)
            .then((res)=>{
                setCom(res.data);
                //console.log(res.data,'first');
            })
            .catch((error)=>console.log(error));
            //console.log("second");
        }
    },[smovie]);

    useEffect(() => {
        axios.get(`https://www.omdbapi.com/?t=${movie.title}&apikey=a8920824`)
            .then((res)=>{
                setSmovie(res.data);
                window.localStorage.setItem('cm',JSON.stringify(res.data));
                setHv('revcied');
            });
        //console.log(res.data);
        //console.log(smovie);
    },[]);

    useEffect(()=>{
        if(user === null){
            navigate('/');
        }
    });

    const postcomment = () => {
        const cm = { 
            id:0,
            movieid:smovie.imdbID,
            name:user.name,
            comment:comvalue
        }
        if( comvalue !== " " && comvalue !== "  " && comvalue !== "   " &&  comvalue !== null && comvalue !== undefined){
            axios.post('https://json-server-public.onrender.com/items',cm)
            .then(()=>{
                //console.log(res);
                axios.get(`https://json-server-public.onrender.com/items?movieid=${smovie.imdbID}`)
                    .then((res)=>{
                    setCom(res.data);
                    //console.log(res.data,'joker');
                    }) 
                .catch((error)=>console.log(error));
            })
            .catch((error)=>console.log(error));
            setComvalue('');
            //setCom(res.data);
        }
    }
    
    return(
        <>
            <Searchbar />
            {smovie.Response === 'True' &&(
                <div className="bodys-container">
                    <div className="movies-container">
                        <img src={base_img_url+movie.img} alt="img" className="detail-poster"/>
                        <div className="details">
                            <h4>Title : {smovie.Title}</h4>
                            <h4>Year : {smovie.Year}</h4>
                            <h4>Genre : {smovie.Genre}</h4>
                            <h4>Type : {smovie.Type}</h4>
                            <h4>imdbRating : {smovie.imdbRating}</h4>
                            <h4>Director : {smovie.Director}</h4>
                            <h4>Actors : {smovie.Actors}</h4>
                            <h4>Runtime : {smovie.Runtime}</h4>
                            <h4>Language : {smovie.Language}</h4>
                            <h4>Country : {smovie.Country}</h4>
                            <h4>Plot : {smovie.Plot}</h4>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Star data={hv}/>
                        </div>
                    </div>
                    <div className="comment-container">
                        <div className="comment">
                            <div className="comment-input">
                                <input value={comvalue} onChange={(e)=>setComvalue(e.target.value)}/>
                                <button onClick={postcomment}>➣</button>
                            </div>
                            { (com.length > 0) ?  (
                                <div className="e-comment">
                                {com.map((com)=>{
                                    return(
                                        <div key={com.id}>
                                            <p>{com.name}</p>
                                            <h4>{com.comment}</h4>
                                            <hr/>
                                        </div>
                                    )
                                })}
                            </div>
                            ) : (<div className="e-comment">no comments</div>)  }
                        </div>
                    </div>
                </div>
            )}
            { smovie.Response === 'False' && (
                <div className="nomovies">No Results Found</div>
            )}    
        </>
    );
}

export default Detailpage;

//smovie.Response === 'Incorrect IMDb ID.'
/*
{ movie === '' && (
                <div className="nomovies">No Results Found</div>
            )}
*/ 
