import React,{ useEffect, useState } from "react";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postvalue } from "./store/movies";
import { imgvalue } from "./store/img";

//import Data from "./db.json"

import Searchbar from "./Searchbar";
import "./Results.css";

const Homepage = () =>{
    const [popmovies,setPopmovies] = useState([]);
    const base_img_url="https://image.tmdb.org/t/p/w500";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(async()=>{
        const response = await axios.get('https://api.themoviedb.org/3/movie/popular?api_key=bcc4ff10c2939665232d75d8bf0ec093');
        setPopmovies(response.data.results);
        //console.log(response.data.results);    
    },[]);

    useEffect(()=>{
        const us = window.localStorage.getItem('cu');
        const user = JSON.parse(us);
        //console.log(user,'from homepage to not login');
        if(user === null){
            navigate('/');
        }
    });
    
    //const base_img_url="https://image.tmdb.org/t/p/w500";
    const setmovieinfo = (movie) => {
        const smi = {
            title:movie.title,
            img:movie.poster_path
        }
        window.localStorage.setItem('sm',JSON.stringify(smi));
    }

    return(
        <>  
            <Searchbar />

            <div className="body-container">
                <div className="topic">Trending</div>
                <div className="movie-box">
                    {popmovies.map((movie)=>{
                        return(
                            <div key={movie.id} className="movie-container" >
                                <div className="poster-containter"><img src={base_img_url+movie.poster_path} alt="img" className="poster"/></div>
                                <Link to="/details" onClick={()=>{
                                    dispatch(postvalue(movie.title));
                                    dispatch(imgvalue(movie.poster_path));
                                    setmovieinfo(movie);
                                }} >
                                        <div className="title">{movie.title}</div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}

export default Homepage;