import React, { useState,useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "../css/Signup.css";

const Signup = () =>{
    const [namevalue,setNamevalue] = useState();
    const [emailvalue,setEmailvalue] = useState();
    const [passwordvalue,setPasswordvalue] = useState();
    const navigate = useNavigate();
    console.log(namevalue);

    useEffect(()=>{
        const us = window.localStorage.getItem('cu');
        const user = JSON.parse(us);
        //console.log(user,'from homepage to not login');
        if(user !== null){
            navigate('/home');
        }
    });

    const onChangesname = (event) =>{
        setNamevalue(event.target.value);
    }

    const onChangesemail = (event) =>{
        setEmailvalue(event.target.value);
    }

    const onChangespassword = (event) =>{
        setPasswordvalue(event.target.value);
    }

    const formvalues = () => {
        if( namevalue !== undefined ){
            const fv = {
                id:0,
                reviewed:false,
                name:namevalue,
                email:emailvalue,
                password:passwordvalue
            }; 
            axios.post('https://json-server-public.onrender.com/user',fv)
                .then(()=>navigate('/login'))
                .catch((error)=>console.log(error));
        }
    }
    
    return(
        <div className="signup-form-container">
            <div className="signup-form">
                <div className="input-field">
                    <label>name</label><br/>
                    <input value={namevalue} onChange={onChangesname}/>
                </div>
                <div className="input-field">
                    <label>email</label><br/>
                    <input value={emailvalue} onChange={onChangesemail}/>
                </div>
                <div className="input-field">
                    <label>password</label><br/>
                    <input value={passwordvalue} onChange={onChangespassword}/>
                </div>
                <div className="button-field">
                    <button className="searchbutton" onClick={formvalues}>
                        signup
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Signup;