import { createSlice } from "@reduxjs/toolkit";

export const imgSlice = createSlice({
    name:"img",
    initialState:{ value:'' },
    reducers:{
        imgvalue:(state,action)=>{
            state.value=action.payload;
        }
    }
});

export const{ imgvalue } = imgSlice.actions;
export default imgSlice.reducer;