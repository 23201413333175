import React,{ useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";

import "../css/Intro.css"

const Intro = () => {
    const navigate = useNavigate();

    useEffect(()=>{
        const us = window.localStorage.getItem('cu');
        const user = JSON.parse(us);
        //console.log(user,'from homepage to not login');
        if(user !== null){
            navigate('/home');
        }
    });

    return(
        <>
            <div className="into-header">
                <div className="logo1">flickinfo</div>
            </div>
            <div className="container"> 
                <div className="info">Search movie,series and tv shows</div>
                <div className="login-title">Login to contiune...</div>
                <Link to="/login" >
                    <div className="login-link">
                        login
                    </div>
                </Link>                       
            </div>
        </>
    );
}

export default Intro;