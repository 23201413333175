import { createSlice } from "@reduxjs/toolkit";

export const currentuserSlice = createSlice({
    name:"currentuser",
    initialState:{ value:[] },
    reducers:{
        currentuservalue:(state,action)=>{
            state.value=action.payload;
            //console.log(state.value,'in store');
        }
    }
});

export const{ currentuservalue } = currentuserSlice.actions;
export default currentuserSlice.reducer;
