import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate,Link } from "react-router-dom";

import { currentuservalue } from "../store/Currentuser";
import "../css/Login.css";

const Login = () => {
    const [namevalue,setNamevalue] = useState();
    const [passwordvalue,setPasswordvalue] = useState();
    const [users,setUsers] = useState();
    const [color,setColor] = useState('silver');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        const us = window.localStorage.getItem('cu');
        const user = JSON.parse(us);
        //console.log(user,'from homepage to not login');
        if(user !== null){
            navigate('/home');
        }
        if(users !== undefined){
            users.map((user)=>{
                if(user.name !== namevalue && user.password !== passwordvalue){
                    setColor('silver');
                }
            });
            users.map((user)=>{
                if(user.name === namevalue && user.password === passwordvalue){
                    setColor('#CAE962');
                }
            });    
        }
    });

    const onChangesname = (event) =>{
        setNamevalue(event.target.value);
    }

    const onChangespassword = (event) =>{
        setPasswordvalue(event.target.value);
    }

    useEffect(()=>{
        axios.get('https://json-server-public.onrender.com/user')
        .then((res)=>{
            setUsers(res.data);
            //console.log(res.data);
        })
        .catch((error)=>console.log(error));
    },[]);

    const formvalues = () => {
        users.map((user)=>{
            if(user.name === namevalue && user.password === passwordvalue){
                user.signedin = true;
                window.localStorage.setItem('cu',JSON.stringify(user));
                dispatch(currentuservalue(user));
                navigate('/home');
                //console.log(user);
                window.localStorage.setItem('cm',JSON.stringify(['hi','dude']))
            }
        });
    }

    return(
        <div className="login-form-container">
            <div className="login-form">
                <div className="input-field">
                    <label>Name</label><br/>
                    <input value={namevalue} onChange={onChangesname}/>
                </div>
                <div className="input-field">
                    <label>Password</label><br/>
                    <input type="password" value={passwordvalue} onChange={onChangespassword}/>
                </div>
                <div className="button-field">
                    <button className="login-button"  style={{ backgroundColor:`${color}` }} onClick={formvalues}>
                        login
                    </button>
                </div>
                <Link to="/signup" >
                    <div className="new-login">Create new account</div>
                </Link>
            </div>
        </div> 
    );
}

export default Login;
